<template>
  <div class="container">
   <div class="row header-tools text-center boxw" >
      <div class="col-2"><i class="fas fa-search"></i><span>Popular</span></div>
      <div class="col-2"><i class="far fa-user-circle"></i><span>Perfis</span></div>
      <div class="col-2"><i class="fas fa-map-marker-alt"></i><span>Localização</span></div>
      <div class="col-2"><i class="fas fa-hashtag"></i><span>Hashtag</span></div>
    </div>
  </div>
  <div class="container mt-3">

  <div class="row feed boxw mb-1">
      <div class=" box pb-2 ">
        <div class="user_info mt-2 mb-2">
          <div class="profile-image">
            <img v-lazy="'https://images.unsplash.com/photo-1513721032312-6a18a42c8763?w=152&amp;h=152&amp;fit=crop&amp;crop=faces'" alt="">
          </div>
          <div class="item-list"><i class="fas fa-certificate badge" style="color:rgb(30,202,255)"><i class="fas fa-check"></i></i></div>
      <div class="item-list">Nome do Perfil</div>
        </div>
    </div>
  </div>
    <div class="row feed boxw mb-1">
      <div class=" box pb-2 ">
        <div class="user_info mt-2 mb-2">
          <div class="profile-image">
            <img v-lazy="'https://images.unsplash.com/photo-1513721032312-6a18a42c8763?w=152&amp;h=152&amp;fit=crop&amp;crop=faces'" alt="">
          </div>
          <div class="item-list"></div><div class="item-list">Nome do Perfil</div>
        </div>
    </div>
  </div>
    <div class="row feed boxw mb-1">
      <div class=" box pb-2 ">
        <div class="user_info mt-2 mb-2">
          <div class="profile-image">
            <img v-lazy="'https://images.unsplash.com/photo-1513721032312-6a18a42c8763?w=152&amp;h=152&amp;fit=crop&amp;crop=faces'" alt="">
          </div>
          <div class="item-list"><i class="fas fa-certificate badge" style="color:rgb(30,202,255)"><i class="fas fa-check"></i></i></div>
      <div class="item-list">Nome do Perfil</div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'profiles'
}
</script>
